import { get, post } from '../utils/api'
import logo from '../assets/images/logo2.svg'
import Config from '../utils/configuration';
import { getUser } from '../utils/authentication';
import { colors } from '../assets/colors';

export const createTempOrder = (payload) => new Promise((resolve, reject) => {
    post('/v1/orders/create-temp-order', payload)
        .then((data) => {
            if (data.status === 1) {
                resolve(data.data)
            } else {
                reject({ message: data.message })
            }
        })
        .catch((error) => {
            reject(error)
        });
})

export const checkout = async (order) => new Promise((resolve, reject) => {
    const user = getUser()
    const { amount, order_id, currency } = order;

    const options = {
        key: Config.RAZORPAY_KEY_ID,
        amount: amount,
        currency: currency,
        name: "Email validify",
        description: "Test Transaction",
        image: { logo },
        order_id: order_id,
        handler: async function (response) {
            post('/v1/orders/create-order', {
                order_id: response.razorpay_order_id,
                payment_id: response.razorpay_payment_id,
                payment_signature: response.razorpay_signature
            })
                .then((data) => {
                    if (data.status === 1) {
                        resolve(data.data)
                    } else {
                        reject({ message: data.message })
                    }
                })
                .catch((error) => {
                    reject(error)
                });
        },
        prefill: {
            name: `${user.first_name} ${user.last_name}`,
            email: user.email,
            contact: user.phone,
        },
        theme: {
            color: colors.green,
        },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.on('payment.failed', function (response) {
        reject({
            code: response.error.code,
            message: response.error.description,
            reason: response.error.reason
        })
    });
    paymentObject.open();
})

export default {
    createTempOrder,
    checkout
}