import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';

import { getUser } from '../../utils/authentication'
import * as UserService from '../../services/users'
import * as UserActions from '../../redux/actions/UserActions'
import * as EmailValidationActions from '../../redux/actions/emailValidationActions'
import EmailValidationStatusModal from '../../components/modals/EmailValidationStatusModal';
import { Logo } from '../../components/logo/Logo';
import { getCreditsBalance } from '../../redux/actions/CreditsActions';
import { layoutGenerator } from 'react-break';

const layout = layoutGenerator({
  mobile: 0,
  tablet: 768,
  desktop: 992,
});

const OnMobile = layout.is('mobile');
const OnAtLeastTablet = layout.isAtLeast('tablet');

const defaultEmailStatus = {
  status: 'unknown',
  message: 'Unknown',
  mx_found: false
}

class Navbar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userDisplayName: 'Anonymous',
      inputEmail: '',
      creditsBalance: 0,
      validationStatus: null,
      showEmailStatusAlert: false
    }
  }

  componentDidMount() {
    const user = getUser()
    if (user && user.first_name) {
      let fullName = user.first_name + (user.last_name ? ' ' + user.last_name : '')
      this.setState({ userDisplayName: fullName })
    }
    if (user) {
      this.loadCreditBalance()
    }
  }

  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  toggleRightSidebar() {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }

  render() {
    return (
      <>
        <OnMobile>
          {this.renderMobileNav()}
        </OnMobile>
        <OnAtLeastTablet>
          {this.renderDesktopNav()}
        </OnAtLeastTablet>
      </>
    )
  }

  renderDesktopNav() {
    return (
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Logo />
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          {this.renderSearchBar()}
          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item"><span className='nav-item highlighted'>{this.props.creditsBalance}</span>Credits</li>
            {this.renderProfileMenu()}
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    );
  }

  renderMobileNav = () => {
    return (
      <nav className="navbar mobile-navbar">
        <div className='nav-top'>
          <div className='nav-left'>
            <Logo />
          </div>
          <div className='nav-right'>
            <div className="nav-item"><span className='nav-item highlighted'>{this.props.creditsBalance}</span>Credits</div>
            <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
              <span className="mdi mdi-menu"></span>
            </button>
          </div>
        </div>
        <div className='nav-bottom'>
          {this.renderSearchBar()}
        </div>
      </nav>
    )
  }

  renderSearchBar = () => {
    const { inputEmail, validationStatus, showEmailStatusAlert } = this.state
    return (
      <div className="search-field d-md-block">
        <div className="d-flex align-items-center h-100" action="#">
          <div className="input-group">
            <div className="input-group-prepend bg-transparent">
              <i className="input-group-text border-0 mdi mdi-magnify"></i>
            </div>
            <input type="text" className="form-control bg-transparent border-0"
              placeholder="Validate an email address"
              value={inputEmail}
              onKeyDown={this.onKeyDownOnSearchInput}
              onChange={e => this.setState({ inputEmail: e.target.value })} />
            <button className="btn btn-sm font-normal" onClick={this.validateEmail}>Validate</button>
          </div>
        </div>
        <EmailValidationStatusModal
          show={showEmailStatusAlert}
          data={validationStatus}
          onClose={() => this.setState({ showEmailStatusAlert: false, validationStatus: null, inputEmail: '' })} />
      </div>
    )
  }

  renderProfileMenu = () => {
    return (
      <li className="nav-item nav-profile">
        <Dropdown alignRight>
          <Dropdown.Toggle className="nav-link">
            <div className="nav-profile-text">
              <p className="mb-1"><Trans>{this.state.userDisplayName}</Trans></p>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="navbar-dropdown">
            <Dropdown.Item href="/app/account" onClick={evt => { }}>
              <i className="mdi mdi-account mr-2 text-success"></i>
              <Trans>Account</Trans>
            </Dropdown.Item>
            <Dropdown.Item onClick={evt => this.logout()}>
              <i className="mdi mdi-logout mr-2 text-primary"></i>
              <Trans>Logout</Trans>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </li>
    )
  }

  onKeyDownOnSearchInput = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.validateEmail()
    }
  }

  loadCreditBalance = () => {
    this.props.dispatch(getCreditsBalance())
  }

  validateEmail = () => {
    const { inputEmail } = this.state
    const { dispatch } = this.props

    if (!!inputEmail) {
      this.setState({ showEmailStatusAlert: true })
      dispatch(EmailValidationActions.validateEmail(inputEmail, response => {
        if (response.status) {
          this.setState({ validationStatus: response.data })
          this.props.dispatch(UserActions.getDashboardData())
        } else {
          defaultEmailStatus.email_address = inputEmail
          this.setState({ validationStatus: defaultEmailStatus })
        }
      })
      )
    }
  }

  logout = () => {
    UserService.logout().then(() => this.props.history.replace('/login'))
  }
}

export default connect((state) => {
  return {
    user: state.users.user,
    creditsBalance: state.credits.creditsBalance,
  };
})(Navbar);
